const loadGoogleMapsApi = require('load-google-maps-api');
class Map {

  static loadGoogleMapsApi() {
    return loadGoogleMapsApi({ key: 'AIzaSyD32IePbwsBeDvpXLN7HHZKXtJv99635og' });
  }
  static createMap(googleMaps, mapElement) {
    const base = { lat: 50.438563, lng: 30.523062 }
    const map = new googleMaps.Map(mapElement, {
      center: base,
      zoom: 16,
      gestureHandling: 'greedy'
    });
    const marker = new google.maps.Marker({position: base, map: map});

    return map;
  }
}
export { Map };
