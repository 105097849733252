//Google Fonts
require('../css/google.fonts.css');

// Bootstrap 4.3.1 CSS
require('../css/bootstrap.css');

// AOS 2.3.4 jQuery plugin CSS (Animations)
import 'aos/dist/aos.css';

// FontAwesome CSS
import '@fortawesome/fontawesome-free/css/all.css';

import 'devicon/devicon.css';

require('../css/style.css');

require('../css/my.css');

// Bootstrap 4.3.1 JS
require('../js/bootstrap.bundle.js');

// Maskedinput jQuery plugin JS (Masks for input fields)
require('../js/jquery.maskedinput.min.js');

require('../js/scroll.js');

import { Map } from './gmap';

document.addEventListener("DOMContentLoaded", function() {
  let mapElement = document.getElementById('map');

  Map.loadGoogleMapsApi().then(function(googleMaps) {
    Map.createMap(googleMaps, mapElement);
  });
});
